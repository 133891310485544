import { Ref } from 'vue';

import { Category, Product } from '@/types/models';
import { ResponseError } from '@/lib/formatErrors';
import { useRequest } from '@/lib/http';
import formatProduct from '@/lib/formatProduct';
import { getProductState } from './use-product';

export const useRecommendedProducts: UseRecommendedProducts = () => {
  const state = getProductState();
  const { errors, loading, request } = useRequest();

  const getRecommendedProducts = async (slug: string, categories: Category[] = []) => {
    const slugParams = categories
      .map((category) => `categories.slug_in=${category.slug}`)
      .join('&');
    const cacheKey = `${slug}:${slugParams}`;

    if (state.value.recommendations[cacheKey]) return state.value.recommendations[cacheKey];

    let products: Product[] = [];

    const params = `_sort=purchases:desc&_limit=9&${slugParams}`;
    const data = await request<Product[]>({ url: `/products?${params}` });

    if (data) {
      state.value.recommendations[cacheKey] = products = data.map(formatProduct);
    }

    return products;
  };

  return { getRecommendedProducts, loading, errors };
};

export type UseRecommendedProducts = () => {
  getRecommendedProducts(slug: string, categories: Category[]): Promise<Product[]>;
  loading: Ref<boolean>;
  errors: Ref<ResponseError[]>;
};
