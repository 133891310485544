import { Ref, ref } from 'vue';

import { Product } from '@/types/models';
import { useSingleProduct, UseSingleProduct } from './signle-product';
import { useRecommendedProducts, UseRecommendedProducts } from './recommended-products';

const state = ref<State>({
  products: {},
  recommendations: {},
});

export const getProductState = (): Ref<State> => state;

const useProduct: UseProduct = () => ({
  useSingleProduct,
  useRecommendedProducts,
});

export default useProduct;

type State = {
  products: Record<string, Product>;
  recommendations: Record<string, Product[]>;
};

type UseProduct = () => {
  useSingleProduct: UseSingleProduct;
  useRecommendedProducts: UseRecommendedProducts;
};
