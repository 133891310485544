import { Ref } from 'vue';

import { Product } from '@/types/models';
import { ResponseError } from '@/lib/formatErrors';
import { useRequest } from '@/lib/http';
import formatProduct from '@/lib/formatProduct';
import { getProductState } from './use-product';

export const useSingleProduct: UseSingleProduct = () => {
  const state = getProductState();
  const { errors, loading, request } = useRequest();

  const getSingleProduct = async (slug: string) => {
    if (state.value.products[slug]) return state.value.products[slug];

    let product: Product | undefined;

    const data = await request<Product>({ url: `/products/${slug}` });

    if (data) {
      state.value.products[slug] = product = formatProduct(data);
    }

    return product;
  };

  return { getSingleProduct, loading, errors };
};

export type UseSingleProduct = () => {
  getSingleProduct(slug: string): Promise<Product | undefined>;
  loading: Ref<boolean>;
  errors: Ref<ResponseError[]>;
};
