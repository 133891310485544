<template>
  <main class="min-h-[540px] max-w-screen-2xl mx-auto mb-8">
    <Skeleton :show="loading">
      <section class="md:h-[600px]">
        <ProductCard v-if="product" :product="product" variant="big" :fixBackDrop="true" />
        <div v-if="errors.length" class="h-[540px] p-4 flex items-center justify-center">
          <div class="p-4 text-center">
            <div class="mb-2" v-for="error in errors" :key="error.id">
              <p class="text-2xl text-gray-800 sm:text-3xl md:text-5xl">
                {{ error.message }}
              </p>
            </div>
            <router-link
              to="/"
              class="
                text-xl text-primary
                border-b border-primary
                hover:text-red-400 hover:border-red-400
              "
            >
              Trouver d'autres produits
            </router-link>
          </div>
        </div>
      </section>

      <template #fallback>
        <ProductCardSkeleton />
      </template>
    </Skeleton>

    <section class="mt-8 px-4 py-8 md:px-14 md:mt-14">
      <h2 class="text-3xl font-heading sm:text-4xl md:text-5xl">Plats Recommandés</h2>

      <Skeleton :show="productsLoading || loading" class="mt-12">
        <ProductsList v-if="product" :products="products" />

        <template #fallback>
          <ProductListSkeleton />
        </template>
      </Skeleton>
    </section>
  </main>
</template>

<script lang="ts">
import { defineComponent, ref, watch } from 'vue';
import { useRoute } from 'vue-router';
import { useMeta } from 'vue-meta';

import { productMetaTags } from '@/lib/metaTags';
import { Product } from '@/types/models';
import ProductCard from '@/components/product/ProductCard.vue';
import ProductsList from '@/components/product/ProductsList.vue';
import Skeleton from '@/components/skeleton/Skeleton.vue';
import ProductCardSkeleton from '@/components/skeleton/ProductCardSkeleton.vue';
import ProductListSkeleton from '@/components/skeleton/ProductListSkeleton.vue';
import useToast from '@/hooks/use-toast';
import useProduct from '@/hooks/product/use-product';

export default defineComponent({
  components: {
    ProductCard,
    ProductsList,
    Skeleton,
    ProductCardSkeleton,
    ProductListSkeleton,
  },
  name: 'Product',
  setup() {
    const { meta } = useMeta(productMetaTags());
    const product = ref<Product | undefined>();
    const products = ref<Product[]>([]);
    const route = useRoute();
    const { useSingleProduct, useRecommendedProducts } = useProduct();
    const { getSingleProduct, loading, errors } = useSingleProduct();
    const { getRecommendedProducts, loading: productsLoading } = useRecommendedProducts();
    const { showToast } = useToast();

    const setMetaTags = (product: Product) => {
      meta.title = `${product.name} | Miam Pizza`;
      meta.description = product.description;
      meta.og = {
        title: meta.title,
        description: meta.description,
        image: product.image?.url,
      };
    };

    const setProduct = async (slug: string) => {
      if (!slug) return;

      product.value = await getSingleProduct(slug);
      if (!product.value) return;

      setMetaTags(product.value);

      products.value = await getRecommendedProducts(product.value.slug, product.value.categories);
    };

    setProduct(route.params.slug as string);

    watch(() => route.params.slug as string, setProduct);

    return {
      products,
      product,
      loading,
      productsLoading,
      errors,
      showToast,
    };
  },
});
</script>
