
import { defineComponent, ref, watch } from 'vue';
import { useRoute } from 'vue-router';
import { useMeta } from 'vue-meta';

import { productMetaTags } from '@/lib/metaTags';
import { Product } from '@/types/models';
import ProductCard from '@/components/product/ProductCard.vue';
import ProductsList from '@/components/product/ProductsList.vue';
import Skeleton from '@/components/skeleton/Skeleton.vue';
import ProductCardSkeleton from '@/components/skeleton/ProductCardSkeleton.vue';
import ProductListSkeleton from '@/components/skeleton/ProductListSkeleton.vue';
import useToast from '@/hooks/use-toast';
import useProduct from '@/hooks/product/use-product';

export default defineComponent({
  components: {
    ProductCard,
    ProductsList,
    Skeleton,
    ProductCardSkeleton,
    ProductListSkeleton,
  },
  name: 'Product',
  setup() {
    const { meta } = useMeta(productMetaTags());
    const product = ref<Product | undefined>();
    const products = ref<Product[]>([]);
    const route = useRoute();
    const { useSingleProduct, useRecommendedProducts } = useProduct();
    const { getSingleProduct, loading, errors } = useSingleProduct();
    const { getRecommendedProducts, loading: productsLoading } = useRecommendedProducts();
    const { showToast } = useToast();

    const setMetaTags = (product: Product) => {
      meta.title = `${product.name} | Miam Pizza`;
      meta.description = product.description;
      meta.og = {
        title: meta.title,
        description: meta.description,
        image: product.image?.url,
      };
    };

    const setProduct = async (slug: string) => {
      if (!slug) return;

      product.value = await getSingleProduct(slug);
      if (!product.value) return;

      setMetaTags(product.value);

      products.value = await getRecommendedProducts(product.value.slug, product.value.categories);
    };

    setProduct(route.params.slug as string);

    watch(() => route.params.slug as string, setProduct);

    return {
      products,
      product,
      loading,
      productsLoading,
      errors,
      showToast,
    };
  },
});
